import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { createIntl, createIntlCache } from "react-intl"
import { defaultLocale, messages } from "../../../i18n/messages"
import { SermonPageQuery } from "../../../../graphql/types"
import { SermonListItem } from "../../../../gatsby/config/create-pages/types"
import { noop } from "@core/utils"
import { PageProps, PageContext } from "@src-types/page"
import { mapSourceSermonToView } from "@features/sermons/mappers/sermon-mapper"
import { SermonDetailsPage } from "@features/sermons/components/sermon-details-page/sermon-details-page"
import { Page } from "@components/page/page"
import { TopicWithArticles } from "@src-types/related-resources"
import { PageBreadcrumbs } from "@core/breadcrumbs"

export interface SermonPageContext extends PageContext {
  createdAt: string
  teacherId: string
  topicCodes: string[]
  topicId: string
}

export const SermonTemplate: FunctionComponent<
  PageProps<
    SermonPageQuery,
    {
      topicArticles: TopicWithArticles
      nextSermon: SermonListItem | null
      previousSermon: SermonListItem | null
    }
  >
> = ({ data, ...props }) => {
  const { nextSermon, previousSermon } = props.pageContext.initialData!

  // The below is necessary because we are not within an IntlProvider
  const cache = createIntlCache()
  const intl = createIntl(
    {
      defaultLocale,
      locale: "en-US",
      messages: messages["en-US"],
      onError: noop
    },
    cache
  )

  const sermon = mapSourceSermonToView(
    data!,
    intl.formatMessage({ id: "sermon-default-description" }),
    nextSermon,
    previousSermon
  )

  const sermonData = data?.contentfulSermon
  const { metadata } = sermonData || {}

  const metadataOverrides = {
    title: metadata?.title || sermon.metadata.title,
    description: metadata?.description || sermon.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || sermon.metadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...sermon.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Sermons,
          {
            label: sermon.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <SermonDetailsPage
        sermon={sermon}
        contentfulId={props.pageContext.contentfulId!}
        primaryTopic={data!.contentfulSermon?.primaryTopic}
      />
    </Page>
  )
}

export default SermonTemplate

export const query = graphql`
  query SermonPage($id: String, $topicCodes: [String]) {
    contentfulSermon(id: { eq: $id }) {
      id
      ...SermonDetails
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
  }
`
