import React, { FunctionComponent, Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { SermonDetails } from "../../sermon-types"
import { Avatar } from "../../../../components/avatar/avatar"
import { IconLabel } from "../../../../components/icon-label/icon-label"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { Link } from "../../../../components/link/link"
import { Audio } from "../../../../components/audio/audio"
import { ScriptureReferenceLabel } from "../../../devotionals/components/devotional-view/devotional-view"
import styles from "./sermon-view.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { PathPrefix, defaultSynopsisMessage } from "@core/constants"

export interface SermonViewProps {
  contentfulId?: string
  sermon: SermonDetails
}

export const SermonView: FunctionComponent<SermonViewProps> = ({
  contentfulId,
  sermon
}) => (
  <Fragment>
    <header className={styles.header}>
      <div className={styles.headerContent}>
        {sermon.date && (
          <IconLabel className={styles.date} variant="12-audio">
            {sermon.date}
          </IconLabel>
        )}
        <Breadcrumbs
          items={sermon.topics}
          className={styles.breadcrumbs}
          size="sm"
        />
        <h1 className={styles.title}>{sermon.title}</h1>
        <div className={styles.author}>
          <Link to={`${PathPrefix.Teachers}/${sermon.teacher.slug}`}>
            <Avatar image={sermon.teacher.image} size="sm" />
            <span>{sermon.teacher.name}</span>
          </Link>
          <ShareAndLikeButton contentType="sermon" id={contentfulId} />
        </div>
        <div className={styles.audio}>
          <Audio
            title={sermon.title}
            src={sermon.audio}
            audioId={`Sermon - ${sermon.title}`}
          />
        </div>
      </div>
    </header>
    <section className={styles.body}>
      <div className={styles.bodyContent}>
        {sermon.scriptureReference && (
          <Link
            to={`${PathPrefix.Scriptures}/${sermon.scriptureReference?.slug}`}
          >
            <ScriptureReferenceLabel
              scriptureReference={sermon.scriptureReference}
            />
          </Link>
        )}
        <Markdown
          content={
            sermon.synopsis?.length ? sermon.synopsis : defaultSynopsisMessage
          }
          className={styles.synopsis}
        />
        {sermon.transcript && (
          <div className={styles.transcript}>
            <h6>
              <FormattedMessage id="daily-video-transcript" />
            </h6>
            <Markdown content={sermon.transcript} />
          </div>
        )}
      </div>
    </section>
  </Fragment>
)
