import React, { FunctionComponent, Fragment } from "react"
import { Navigation } from "../../../../components/navigation/navigation"
import { SermonDetails } from "../../sermon-types"
import { SermonView } from "../sermon-view/sermon-view"
import { RecombeePrimaryTopic } from "@src-types/recombee"
import { Recommendations } from "@components/recommendations/recommendations"
import recomStyles from "@components/recommendations/recommendations.module.scss"
import { PathPrefix } from "@core/constants"

export interface SermonDetailsPageProps {
  contentfulId?: string
  sermon: SermonDetails
  primaryTopic?: RecombeePrimaryTopic | null
}

export const SermonDetailsPage: FunctionComponent<SermonDetailsPageProps> = ({
  contentfulId,
  sermon,
  primaryTopic
}) => {
  const { teacher } = sermon

  return (
    <Fragment>
      <SermonView sermon={sermon} contentfulId={contentfulId} />
      <Navigation previous={sermon.previousSermon} next={sermon.nextSermon} />
      <div className={recomStyles.relatedResourcesWrapper}>
        {teacher?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "teacher",
              slug: teacher.slug,
              idsToRemove: [contentfulId || ""],
              itemId: contentfulId
            }}
            title={teacher.name || ""}
            overline="More from this teacher"
            body={teacher.shortBio || ""}
            link={`${PathPrefix.Teachers}/${teacher.slug}`}
          />
        )}
        {primaryTopic?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "topic",
              slug: primaryTopic.slug,
              idsToRemove: [contentfulId || ""],
              itemId: contentfulId
            }}
            title={primaryTopic.name || ""}
            overline="More on this topic"
            body={primaryTopic.description?.description}
            link={`${PathPrefix.Topics}/${primaryTopic.slug}`}
          />
        )}
      </div>
    </Fragment>
  )
}
