import React, { FunctionComponent, ReactText } from "react"
import { NavigationItem } from "../../types/navigation"
import { NavigationLink } from "./navigation-link"
import styles from "./navigation.module.scss"

export interface NavigationProps {
  previous: NavigationItem | null
  next: NavigationItem | null
  time?: ReactText | number
}

export const Navigation: FunctionComponent<NavigationProps> = ({
  previous,
  next,
  time
}) => {
  if (!previous && !next) {
    return null
  }

  return (
    <div className={styles.navigation}>
      <NavigationLink item={previous} type="previous" time={time} />
      <NavigationLink item={next} type="next" time={time} />
    </div>
  )
}
