import { IntlShape } from "react-intl"
import {
  ContentfulTeacher,
  SermonPageQuery,
  SermonBaseFragment
} from "../../../../graphql/types"
import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"
import { PathPrefix } from "../../../core/constants"
import { SermonDetails } from "../sermon-types"
import { NavigationItem } from "../../../types/navigation"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import {
  formatScriptureReference,
  ScriptureReference,
  mapScriptureReference,
  getTranslateableScripture
} from "../../scriptures/mappers/scripture-reference-mapper"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { SermonListItem } from "../../../../gatsby/config/create-pages/types"
import { TranslateableString } from "../../../types/intl"
import { getScriptureTranslationId } from "../../scriptures/scripture-utils"

export const getBook =
  (intl: IntlShape) =>
  (key: string): string => {
    return intl.formatMessage({
      id: `scripture-${key ? key.toLowerCase() : ""}`
    })
  }

export const mapNavigationItem = (data: SermonBaseFragment): NavigationItem => {
  return {
    title: data.sermonTitle!,
    subtitle: data.primaryScriptureReference?.start?.book
      ? (intl: IntlShape) =>
          formatScriptureReference(
            data.primaryScriptureReference as ScriptureReference,
            (key) =>
              intl.formatMessage({
                id: getScriptureTranslationId(key)
              })
          )
      : "",
    link: `${PathPrefix.Sermon}/${data.slug!}`
  }
}

export const mapSourceSermonToView = (
  query: SermonPageQuery,
  defaultDescription: string,
  nextSermon: SermonListItem | null,
  previousSermon: SermonListItem | null
): SermonDetails => {
  const details = query.contentfulSermon!

  const topics: BreadcrumbResource[] = query.allContentfulTopic.nodes.map(
    (node) => ({
      label: node.name!,
      link: `${PathPrefix.Topics}/${node.slug}`
    })
  )

  const topic = details.primaryTopic!

  topics.push({
    label: topic.name!,
    link: `${PathPrefix.Topics}/${topic.slug}`
  })

  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])

  const audio =
    details.media?.externalAudioUrl ||
    details.media?.uploadAudio?.file?.url ||
    ""

  const scriptureReference =
    details.primaryScriptureReference &&
    details.primaryScriptureReference.start?.book
      ? mapScriptureReference(
          details.primaryScriptureReference as ScriptureReference
        )
      : null

  // Metadata Description
  // 1. Sermon Synopsis
  // 2. Sermon Transcript
  // 3. Sermon Intl Description
  const sermonSynopsis =
    details.sermonSynopsis?.childMarkdownRemark?.rawMarkdownBody
  const sermonSynopsisText =
    details.sermonSynopsis?.childMarkdownRemark?.plainText
  const sermonTranscript =
    details.sermonTranscript?.childMarkdownRemark?.rawMarkdownBody
  const sermonTranscriptText =
    details.sermonTranscript?.childMarkdownRemark?.plainText
  const metaFieldJSON = sermonSynopsisText || sermonTranscriptText
  const partialDescription = metaFieldJSON || defaultDescription

  const metaDescription: TranslateableString = scriptureReference
    ? (intl: IntlShape) => {
        const translateScripture = getTranslateableScripture(scriptureReference)

        return `A sermon on ${translateScripture(intl)}. ${partialDescription}`
      }
    : partialDescription

  const metadata = mapMetadata(
    details.metadata,
    {
      title: details.sermonTitle
    },
    {
      title: details.pageTitle,
      description: metaDescription
    }
  )

  return {
    id: details.id,
    slug: details.slug!,
    title: details.sermonTitle!,
    date: details.datePreached || "",
    scriptureReference: scriptureReference || undefined,
    topics,
    synopsis: sermonSynopsis || "",
    transcript: sermonTranscript || "",
    audio,
    teacher: teachers[0],
    previousSermon: previousSermon ? mapNavigationItem(previousSermon) : null,
    nextSermon: nextSermon ? mapNavigationItem(nextSermon) : null,
    metadata
  }
}
